.container {
  margin: 28px 0;
}

.title {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.search__container {
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
  margin-right: 70px;
}

.search__input {
  width: 68%;
  padding: 17px 28px;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border: none;
  font-size: 22px;
  line-height: 26px;
}

.search__input:focus-visible{
  border: none;
  box-shadow: 0px 0px 10px var(--hover-shadow-red);
  outline: none;
}


.search__button {
  min-width: 170px;
}

.filter__container {
  max-width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter__label {
  font-size: 20px;
  line-height: 23px;
}

.filter__select {
  font-size: 18px;
  line-height: 21px;
  border: 1px solid var(--grey);
  background-color: var(--white);
  padding: 5px 10px;
  margin-left: 16px;
}

.filter__input {
  max-width: 179px;
  font-size: 18px;
  line-height: 21px;
  border: 1px solid var(--grey);
  background-color: var(--white);
  padding: 5px 10px;
  margin-left: 16px;
}

.result__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.item__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 9px;
}

.item__image {
  display: block;
  max-width: 32px;
  max-height: 32px;
}

.item__info {
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 679px;
}

.item__title {
  margin: 0;
  color: var(--cian-text);
  font-size: 18px;
  line-height: 34px;
  text-decoration-line: underline;
}

.item__status {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}

.item__status_done {
  color: var(--cian-text);
}

.item__status_open {
  color: var(--black);
}

.rating__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 52px;
  align-self: flex-end;
}

.rating {
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  color: var(--grey);
}

.rating__button {
  background-image: url(../../images//rating-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: inherit;
  padding: 0;
  border: none;
  min-width: 38px;
  min-height: 14px;
  cursor: pointer;
}

.rating__button_dec {
  transform: rotate(180deg);
}