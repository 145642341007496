@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
  --red: #B40307;
  --white: #ffff;
  --hover-shadow-red: rgba(180, 3, 7, .4);
  --violet: #130224;
  --grey: #C4C4C4;
  --black: #070709;
  --cian: rgba(130, 201, 195, .5);
  --cian-text: #82C9C3;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

.link {
  text-decoration: none;
  color: inherit;
  transition: all 0.2s;
}

.link:hover {
  color: var(--red);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pages__container {
  display: grid;
  grid-template-columns:1fr repeat(11, 70px 30px) 70px 1fr;
  grid-template-rows: min-content 1fr;
  margin-top: 32px;
  min-height: calc(100vh - 288px);
}
