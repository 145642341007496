.container {
  display: flex;
  gap: 10px;
  align-self: baseline;
}

.button {
  background-color: var(--white);
  max-width: 170px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  padding: 16px 45px;
  border: none;
  background-repeat: no-repeat;
  text-align: center;
  background-position: 6% 50%;
}

.button:hover {
  color: inherit;
}

.button_type_creator {
  background-image: url(../../images/switch-role/creator.png);
}

.button_type_consumer {
  background-image: url(../../images/switch-role/consumer.png);
}

.button_active_type_consumer {
  background-image: url(../../images/switch-role/consumer_active.png);
  background-color: var(--grey);
}

.button_active_type_creator {
  background-image: url(../../images/switch-role/creator_active.png);
  background-color: var(--grey);
}