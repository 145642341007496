.howItWorks__card {
  display: grid;
  grid-template-columns: repeat(12, 70px);
  grid-template-rows: 1fr;
  gap: 30px;
} 

.howItWorks__card:nth-child(odd) .card__info {
  grid-column-start: 1;
  grid-column-end: 5;
}

.howItWorks__card:nth-child(even) .card__info {
  grid-column-start: 7;
  grid-column-end: 11;
}

.howItWorks__card:nth-child(odd) .card__img {
  grid-column-start: 9;
  grid-column-end: 12;
  grid-row-start: 1;
}


.howItWorks__card:nth-child(even) .card__img {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
}

.card__img {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}


.card__info {
  background-repeat: no-repeat;
  padding-top: 180px;
  background-size: contain;
  background-position: right;
}

.card__info_type_one {
  background-image: url(../../images/how-it-works-img/type_one.png);
}

.card__info_type_two {
  background-image: url(../../images/how-it-works-img/type_two.png);
}

.card__info_type_three {
  background-image: url(../../images/how-it-works-img/type_three.png);
}

.card__info_type_four {
  background-image: url(../../images/how-it-works-img/type_four.png);
}
