.card {
  position: relative;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  min-height: 235px;
  padding: 0 30px;
  text-align: start;
  cursor: pointer;
  transition: all 0.4s;            
}

.card__circle {
  position: absolute;
  border-radius: 50%;
  background-color: var(--red);
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  width: 80px;
  height: 80px;
  top: -40px;
  left: 48px;
  background-repeat: no-repeat;
  background-position: center;
}


.card:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 40px var(--hover-shadow-red);
}

.card__info {
  margin-top: 76px;
}