.popup {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.popup__header{
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
  padding: 10px 10px;
}

.popup__button_type_close {
  background-color: inherit;
  border: none;
  outline: none;
  background-image: url(../../images/close-modal.svg);
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 12px;
  min-height: 12px;
  cursor: pointer;
}
