@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
:root {
  --red: #B40307;
  --white: #ffff;
  --hover-shadow-red: rgba(180, 3, 7, .4);
  --violet: #130224;
  --grey: #C4C4C4;
  --black: #070709;
  --cian: rgba(130, 201, 195, .5);
  --cian-text: #82C9C3;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

.link {
  text-decoration: none;
  color: inherit;
  transition: all 0.2s;
}

.link:hover {
  color: #B40307;
  color: var(--red);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pages__container {
  display: grid;
  grid-template-columns:1fr repeat(11, 70px 30px) 70px 1fr;
  grid-template-rows: -webkit-min-content 1fr;
  grid-template-rows: min-content 1fr;
  margin-top: 32px;
  min-height: calc(100vh - 288px);
}

.app_page__xu4mx {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.header_header__v9FKw { 
  border-bottom: 1px solid var(--grey);
  padding: 26px 0;
  animation: header_header__v9FKw .5s forwards ease;
  
}

.header_header__content__3l1X7 {
  display: grid;
  grid-template-columns: 1fr repeat(11, 70px 30px) 70px 1fr;
  transition: left .2s linear;
  animation: header_header__content__3l1X7 .5s forwards ease;
}

.header_header__logo__36Cdj {
  transition: filter 0.4s;
  grid-column:2 / 6;
}

.header_header__logo__36Cdj:hover {
  filter: brightness(0.8);
}

.header_header__nav__1aPAB {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: 7 / 20;
}


.header_header__auth__3YB4Z {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 188px;
  grid-column: 21 / 25;

}

.header_header__auth_type_authorized__VNlQb {
  justify-content: flex-end;
}

.header_header__link__1Rp_k {
  color: var(--black);
}

.header_header__link_active__3tjF4 {
  color: var(--red)
}

.header_header__link_type_auth__X_ziz {
  border-bottom: 1px dashed var(--black);
  transition: border-color 0.2s;
}

.header_header__link_type_authorized__2y5SC {
  background-image: url(/static/media/auth__icon.e58ca195.svg);
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 32px;
  min-height: 36px;
  cursor: pointer;
}

.header_header__link_type_auth__X_ziz:hover {
  border-color: var(--red);
}

@keyframes header_header__v9FKw {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes header_header__content__3l1X7 {
  from {
      opacity: 0;
      transform: translate(0, 50px);
  }
  to {
      opacity: 1;
      transform: translate(0, 0);
  }
}
.footer_footer__1lZIc {
    background-color: var(--black);
    margin: 0 auto;
    width: 100%;
}

.footer_footer__container__18NaH {
  display: grid;
  max-width: 1170px;
  grid-template-columns: repeat(12, 70px);
  grid-column-gap: 30px;
  grid-row-gap: 19px;
  margin: 0 auto;
  color: var(--white);
  align-items: center;
  padding: 12px 0 22px;
}

.footer_footer__logo_link__HJe9C {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
}

.footer_footer__links__3h5x_ {
    grid-column-start: 2;
    grid-column-end: 13;
    grid-row-start: 1;
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
}

.footer_footer__copyright__10jkG {
    grid-column-start: 2;
    grid-column-end: 11;
    grid-row-start: 2;
    text-align: center;
    margin: 0;
    align-self: end;
}

.footer_footer__social__2hynV {
    grid-column-start: 10;
    grid-column-end: 13;
    grid-row-start: 2;
    justify-self: end;
}

.main-page_main__content__1Id4p {
  padding-top: 118px;
}


.button_button__3HJzU {
  border-radius: 30px;
  padding: 18px 43px;
  border: 1px solid transparent;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer; 
}

.button_button_type_background__2Tvz4 {
  background-color: var(--red);
  color: var(--white);
  transition: box-shadow 0.5s;
}

.button_button_type_background__2Tvz4:hover {
  box-shadow: 0px 5px 40px var(--hover-shadow-red);
}

.button_button_type_outline__2aqX2 {
  background-color: var(--white);
  border-color: var(--red);
  color: var(--red);
  transition: background-color 0.8s;
}

.button_button_type_outline__2aqX2:hover {
  background-color: var(--red);
  color: var(--white);  
}
.main-control_main__control__2ZY_X {
  display: grid;
  grid-template-columns: repeat(12, 70px);
  grid-gap: 30px;
  gap: 30px;
}

.main-control_control__info__15YIi {
  grid-column-start: 1;
  grid-column-end: 7;
}

.main-control_control__title__30s_X {
  font-size: 50px;
  line-height: 59px;
  margin-top: 17px;
}

.main-control_control__items__5SGYp {
  padding-left: 20px;
  padding-bottom: 0;
}

.main-control_control__item__cyLAH {
  line-height: 38px;
}

.main-control_control__buttons__2yQZ_ {
  display: flex;
  grid-gap: 41px;
  gap: 41px;
  margin-top: 63px;
}

.main-control_control__img__RZlXp {
  grid-column-start: 8;
  grid-column-end: 11;
}
.main-content-cards_main__contentCards__2Lf3D {
  display: grid;
  grid-template-columns: repeat(12, 70px);
  grid-gap: 30px;
  gap: 30px;
  margin-top: 136px;
}

.main-content-cards_contentCards__title__LPDQa {
  font-size: 50px;
  margin: 0;
  grid-column-start: 1;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 1;
}


.main-content-cards_contentCards__link__1Muf3 {
  color: var(--black);
  transition: all 0.2s;
  grid-column-start: 9;
  grid-column-end: 12;
  grid-row-start: 2;
  grid-row-end: 2;
}

.main-content-cards_contentCards__link__1Muf3:hover {
  color: var(--red);
}


.main-content-cards_contentCards__cards__1ktVe {
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 100px;
  gap: 100px;
  margin-top: 53px;
}
.main-card_card__2XCk- {
  position: relative;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  min-height: 235px;
  padding: 0 30px;
  text-align: start;
  cursor: pointer;
  transition: all 0.4s;            
}

.main-card_card__circle__1XbQU {
  position: absolute;
  border-radius: 50%;
  background-color: var(--red);
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  width: 80px;
  height: 80px;
  top: -40px;
  left: 48px;
  background-repeat: no-repeat;
  background-position: center;
}


.main-card_card__2XCk-:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 40px var(--hover-shadow-red);
}

.main-card_card__info__17cs- {
  margin-top: 76px;
}
.main-how-it-works_main__howItWorks__jD_Fg {
  margin-top: 246px;
  display: flex;
  flex-direction: column;
  grid-gap: 140px;
  gap: 140px
}
.how-it-works-card_howItWorks__card__15ZiO {
  display: grid;
  grid-template-columns: repeat(12, 70px);
  grid-template-rows: 1fr;
  grid-gap: 30px;
  gap: 30px;
} 

.how-it-works-card_howItWorks__card__15ZiO:nth-child(odd) .how-it-works-card_card__info__3DNg- {
  grid-column-start: 1;
  grid-column-end: 5;
}

.how-it-works-card_howItWorks__card__15ZiO:nth-child(even) .how-it-works-card_card__info__3DNg- {
  grid-column-start: 7;
  grid-column-end: 11;
}

.how-it-works-card_howItWorks__card__15ZiO:nth-child(odd) .how-it-works-card_card__img__2iqiA {
  grid-column-start: 9;
  grid-column-end: 12;
  grid-row-start: 1;
}


.how-it-works-card_howItWorks__card__15ZiO:nth-child(even) .how-it-works-card_card__img__2iqiA {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
}

.how-it-works-card_card__img__2iqiA {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}


.how-it-works-card_card__info__3DNg- {
  background-repeat: no-repeat;
  padding-top: 180px;
  background-size: contain;
  background-position: right;
}

.how-it-works-card_card__info_type_one__1HKX6 {
  background-image: url(/static/media/type_one.b9fff359.png);
}

.how-it-works-card_card__info_type_two__1RrQY {
  background-image: url(/static/media/type_two.38f955e0.png);
}

.how-it-works-card_card__info_type_three__1_zVN {
  background-image: url(/static/media/type_three.188ade71.png);
}

.how-it-works-card_card__info_type_four__3FdRD {
  background-image: url(/static/media/type_four.8cf01ed6.png);
}

.informing_informing__10qpg {
  margin: 64px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 36px;
  max-width: 830px;
}

.informing_informing__title__379xo {
  font-size: 36px;
  margin: 0 0 30px;
}

.informing_informing__text__33Y0- {
  margin: 0 0 26px;
}
.request_container__DRqtA {
  margin: 28px 0;
}

.request_title__2hYFV {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.request_search__container__3KzyQ {
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
  margin-right: 70px;
}

.request_search__input__2Q4cq {
  width: 68%;
  padding: 17px 28px;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border: none;
  font-size: 22px;
  line-height: 26px;
}

.request_search__input__2Q4cq:focus-visible{
  border: none;
  box-shadow: 0px 0px 10px var(--hover-shadow-red);
  outline: none;
}


.request_search__button__1Hn5S {
  min-width: 170px;
}

.request_filter__container__346bX {
  max-width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.request_filter__label__y0Aa- {
  font-size: 20px;
  line-height: 23px;
}

.request_filter__select__2hy6Z {
  font-size: 18px;
  line-height: 21px;
  border: 1px solid var(--grey);
  background-color: var(--white);
  padding: 5px 10px;
  margin-left: 16px;
}

.request_filter__input__e2D9J {
  max-width: 179px;
  font-size: 18px;
  line-height: 21px;
  border: 1px solid var(--grey);
  background-color: var(--white);
  padding: 5px 10px;
  margin-left: 16px;
}

.request_result__item__3Zag_ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.request_item__container__2-EfV {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 9px;
  gap: 9px;
}

.request_item__image__2ULtk {
  display: block;
  max-width: 32px;
  max-height: 32px;
}

.request_item__info__3vEH2 {
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 679px;
}

.request_item__title__36x1J {
  margin: 0;
  color: var(--cian-text);
  font-size: 18px;
  line-height: 34px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.request_item__status__2KrUQ {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}

.request_item__status_done__2Iota {
  color: var(--cian-text);
}

.request_item__status_open__1VPED {
  color: var(--black);
}

.request_rating__container__2Mxuu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 52px;
  align-self: flex-end;
}

.request_rating__1u-eN {
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  color: var(--grey);
}

.request_rating__button__PZxDo {
  background-image: url(/static/media/rating-button.9b5c3a23.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: inherit;
  padding: 0;
  border: none;
  min-width: 38px;
  min-height: 14px;
  cursor: pointer;
}

.request_rating__button_dec__3HyVc {
  transform: rotate(180deg);
}
.auth_container__3M9A_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 70px 91px;
}

.auth_auth__title__19g2s {
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
  margin: 55px auto 38px;
}

.auth_auth__form__1_yep {
  display:  flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  min-width: 264px;
}

.auth_auth__label__NmN6T {
  display: flex;
  flex-direction: column;
  grid-gap: 14px;
  gap: 14px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  position: relative;
}

.auth_auth__input__XmIUQ {
  border: 1px solid var(--grey);
  padding: 10px 7px;
  margin-left: 6px;
}

.auth_auth__input__XmIUQ:focus {
  box-shadow: 3px 3px 3px var(--grey);
}

.auth_auth__input__XmIUQ:focus-visible {
  outline: var(--grey);
  box-shadow: 3px 3px 3px var(--grey);
}

.auth_auth__button_type_password__2yvAo {
  padding: 0;
  position: absolute;
  
  right: 10px;
  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-position: center;
  background-color: inherit;
  min-width: 32px;
  min-height: 32px;
  top: 50%;
}

.auth_password__button_type_active__2DeqQ {
  background-image: url(/static/media/eye-active.d6f9ad15.svg);

}

.auth_password__button_type_disabled__1lyKk {
  background-image: url(/static/media/eye-disabled.bc093246.svg);

}

.auth_auth__button_type_submit__3dRRF {
  margin: 24px auto 35px;
  max-width: 169px;
  
}

.auth_auth__inform_medium__3FLS8 {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.auth_auth__link_type_register__5diSN {
  text-decoration: underline;
}
.modal_popup__lnicy {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.modal_popup__header__34yB5{
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
  padding: 10px 10px;
}

.modal_popup__button_type_close__yU72L {
  background-color: inherit;
  border: none;
  outline: none;
  background-image: url(/static/media/close-modal.a0cc25dd.svg);
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 12px;
  min-height: 12px;
  cursor: pointer;
}

.modal-overlay_overlay__AKixE {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: .2;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;

}
.layout_switch__2x4eb {
  grid-row: span 1;
  grid-column: 9 / 25;
}

.layout_child__container__nVQHt {
  grid-row-start: 2; 
  grid-row-end: 3;
  grid-column: 8 / 25;
}
.switch-role_container__120Dm {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-self: baseline;
}

.switch-role_button__w9MlW {
  background-color: var(--white);
  max-width: 170px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  padding: 16px 45px;
  border: none;
  background-repeat: no-repeat;
  text-align: center;
  background-position: 6% 50%;
}

.switch-role_button__w9MlW:hover {
  color: inherit;
}

.switch-role_button_type_creator__3NxEz {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAR3SURBVHgBtZd/aFtVFMfvS1+aOn9QKYpzFROnfxdFZmmSuVBma6qxOC1Ilf0hU9lfCqKoMOo/IojgL/z5hzA6RTa1WzdcO0PapF3/ay3+QKiuhf6xFZot0HY1tE39nNcbfIsveS/bcuD23HvPzfl+z7n3nndrqBrJ6OhoqK6uLmUYxp/ZbPbJRCJx2WmdoWogRXC6d1sghpGERMKJhE9dZ7GDb25ufoj+G93e1NR0AtlWMwKpVKoxnU5328DfjkajL29sbMQqkbimLRBQ0zR7SPFTDB+i3aJNc2tra/fHYrGcDMjKXZrYztLtqJrA1NRU4/Ly8n4cdTMM0/wyT4Q55r6n+yCthfH0+vr6HjcSngiQ2u0+n6+XbhdtT3FeQJk/XCgUflxaWpqIx+P5TCZzKwApryRcCYyPjz+CoyEb6AXUN7RB9njE6TdeSYgPLwQO4+Q5up/g9Gg4HE4rD1KJxNjY2DHUPubPVSSQTCZ3BAKBeRaej0QizTjcVFWIEwmi/4Bt26+2Dmqs4jVsaGjYq6w6YgxXCy7CFl0CWK7hNL9v8fv9s3ZwZK4iAX78gmiYf6muUuwkaI12cLGX3QLSdw+spYCcw8lOu03uP9HsxnYTEf3BufhFuQj7PozaK2Tsh7dsBgB/Tev3ShwlKD6zdI9jO4LDKeb6hVQ5X5OTk7dp8PnSm2Oq8tIhf0jX6eIEIEFdbEycHSP639Gyp71kpAn9qJOj1dXVTh1MstTmmAG5+6ggzs8U90qkvr7+EHMmhectInma1PcxfkBuCeZO+RA5+WN9txWtaX7riQAOe/UPj5TMhzSRo8U5OWSoAR1hS6mvmZmZAKoLW7a1tXXYlQD7eSeLn6V7fmFhoV9dGUlWNNuywz7P+nt1hJdK/S0uLgp4APKnlYP87wywWEqvEBvq6enZsNsoIgPY9rHma4i+DqHfOAcHMEm9mGtraxst9cfn+AkrUp/vuCcCOD0AgPzgK/u8VEVsh8SGBGnfscayQeoitrhyFjmAa7STrgTkEAHQhsNZojlrB2ff5X13H0P5FnxKe4x2I+1XCtXHHNbFUudkSTJzO+ROUMpXXQkQ0au6+3458JWVlXhHR8eKZEBVEKkLBHJQZ/OHcuuuIMBiK41EdMoF3FH6+vrM9vb2COvfYBil3UArcGiHXAmMjIxIuoK0n+XuVwPOgyXMOtmSl9BWRZTHCuozxgP4u+BKgCvUq7v9XsD1e/B50vs4YA/r6YLEwp6/m8vlMuX+F7CLoZ3dQSmdp3sxn8/vAvyME7hUQaqkpPZNtZXigOXEMM7SBvnt58VHh1exMgBgpzinO+0EzmmOMteFftGeYqKXV9Kg0/33KlYGcJxBRdTWfZVXbpqDIx+YZ9TWddutQdcBzKDfaW5uHguFQv+oaxRzYmIiSLWK6LEfgMvsYQPgf6n/Upxh7hS344tqU+xKAMev2CeIbhuAu/Q7/yPaSa8P0asRg/TL4yLoYJNPbF7VQAjuJ76iB6UvB69QZt12VSMhqzcX+/8ClneYlQVQgMoAAAAASUVORK5CYII=);
}

.switch-role_button_type_consumer__119yo {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPqSURBVHgBrZdPSNRBFMfnt+pqmdBWVGyHtCDqFJUZ+S9NyUsYBULRoYMURoTWJQJFBaGEqITq0iXslF0kuqmZ+Weh0jI8RIUpaFHKaoLb6qrbZ9b9yc/f/tZ1fvZgeDPz3rz3nTfz5o8mLKirq+s7LFX8X+rNzs5ON3fGR9N2Op27MzIyBsUK1NnZeRvmz8nJqYmhl6dp2h0rmWZS9KLo0gHMzMw00T4UxW5ZMBhMkwAcDgfVYHUUvQ5ktdhpDznUtKGsrKw0Xegwa8/Pzx+EDS0h1LRzGNhkLHQ/M45xu9319G+OUs6E1XqpRyxBBICUlBSvsb2wsLAXEH3M8hShnpCFPr9RZ3R0tEzqGAv6dZQDlLsG1Qmzv6h7wED7g4tUxeaUYW6gvhPu0RUCgcCTpKSkZuOgqakpn8fjGS8qKhoEcGo047EAtDCbHPgfeDOOa6ifoj4Glxs0tJbx8fEXWLqrxoHJycmisLBQRvANzUZbAAj3zfAObsf5T8J5JDMz860uR1YgOfLnsG4rGwDzId9qC4Ckubm5ocTExGWOzUR+/yDc2Ti7bBJ9yM3NvQ5QewAY+Aj0+wijPJyWyeh7aAKahu4skbol29QzYfkiBq0IgBk9ZX03WMni4uL6AbEsrWSOE43Xst7d3b0FMGsDgPMz4R1vdPKYkkp/xFgAFRK1JllH7ob9FWsBgKMWjLqNfYT6K8AC8BH4MYPuS3R/6W0AyOIVdgH4/f57hNlLWdZPO+SUo1qET7Y5wr0zLIuwg+w0bKMEpALgBjPaKDefmUjFRPrXh5vp6A3T9ogYxDivFQhLAGykJqv+np6eKpxV4tQp2xj8xClYkZ+fPyRWQeyPXTEBTE9PX0Nx0soAWVHOTPZwmw2bDOfB8kRscsUCUE9ZZz1WrGPmYnZ2VhP2aYKoNRg7lIwx0xpAGO/9L7wZThQUFAwLm+RQUZYvH265JP1dwH54RzacFWsg2+EkGnI9G4nIOBuxdrUb0UxKEdCJ3K4Ti9exPOm2JyQkfKOv2o4t5QhIR4S/hJkf5zTcRmYsUJ8GRCviB6TwfRV7yhHA+UVCfpJU/E1KnsdxiQw/9VKAVApFUgLQ0dERegHp6y1vPxyH6rwX5Ot3fVtb2w6hQKt5Ey4RzvCpBcIHj4zGZ3m86m1JLpdLyaaScthparRPhh17SgPI+yBXsPxYpFvJ5cdGKJLSHuC6nSEC8mqNyJ6BgQGn/FWNjIyMCQVSAiAfnzjpJxUvmWWTk5PlyF4VFxf7hAIprxlUQWkFxAbOgRfU/SxLKctzhfQ8LBRJ+Rxg/T/iKJ1lOMqSvCcz+nB+yOfzZdg5jv8Bjpv3ZMBodhYAAAAASUVORK5CYII=);
}

.switch-role_button_active_type_consumer__2eCs4 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALASURBVHgBtZddiIxRGMefnWSzKbtIIs0gWkm+tpRoR9xv3LlyiQv5SpsLWaVQor3gxo1w4+NCcbfJUlJatOWCyM7WRppt1t5grIzfY5/RcfZ9Z+a8M/OvX+95z3nOeZ5z3vP1ikSoVCqNlBqvoShfKYnXypYqwuYCnKnBbkeck5TX84KGSjJj70MVerTfqXcafsfw2Mw2W72R2ABMmyDnvO+F+R63vTo6Egti2GM2L6FLKo2AqeC9d8Ir6GE0JxTSPzybA2bjchY2wiXHbsJ3Nkuqaz3oZzmlQ82zH9Lw3LG5Dve9et9gHD6KfdIoVQtgALbDpDnogx7IW8PLzW4fHIpp4ynckCQBMNwn6XWWpE6kz7CFvBflcsp2WvIuPItpRkdikSQJwJTzHUcE+olgtpE86BW9puwYZckCoOJVHmss7Rdf8d71c/yEc/a+VSqs/5oCQDdhbkzZsMxcVjl6PKgJAl7YiAB0Dae9vGsyPauj6u7C8R1LL4HvUmcAA9aQq/cwBWPQ7eQ/hC+ebUHqCOByTAOuU/0Ev+h1Oq4RynbzaJfAAHorVGqFNieAUfl/U4pT1dEoH0aVeqO7YdE5kIYhIzUK2xX+YRQ1Akcx+hrTxmFYzUwf9RrO8shKdXX4GX4AeqrNia77L79FkksPo35JKnra590J3lX6ZE0RDluhw7gFvVKHUoH2uu8X7U6gmgedIROx7gBUONTLhh7HutMthg92V2i+7P73Rk84WAdrdQRAgzgizRZOxspDzvO8TkxLd8O4BCroE+Dg7w2IOZCzrJyheU94tGGzVAJUy4XEle4BU7bxqN5aYNmkbYYGoMrAxUa1F1pBr0V66eiKLOQskUCFLsMitOOoJcL5bJne6/PSTOFo0P0tc/JPwCNptnCyQZcbHIdVsMzOiHw9O2JoELrx3INJc/ygvERD9Qdlg/8c+TtIDAAAAABJRU5ErkJggg==);
  background-color: var(--grey);
}

.switch-role_button_active_type_creator__2X6_g {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMdSURBVHgBvVZLaBRBEO01CfGDYEDBGMVE9KAnryr+QONBkFxUkEg8iejBg4riKZ4UUTSI4kE0am7+0EtiIsYoEbzlLGpE0BAwLEI+Zqe62zdtzWRmdma2d7O7BUX3VFfVe13TPyEqJHpmpkVL+V0r1ae1XiyqKT64lNqoUm+qRiIE7jg3Af6lKiSQfJkmaguAd7J9TcVIMOhxJO4HwJ9AyUfdsYBf+UgYUClPI9Eg2pwPKmUWeg86wt8jeSSkLI0EnBsReJZBdQjUcbqgu+BTz74NZSWB5K0hUKXGYLvugqYQTicx9zteFSYg5SNOdAugO4SlpJIgesr2r4WSNMFZwfEn+hlRpMSRQL5u/h7Flm1OT0B0jJ0fiBIlQiJrDW6ClfpoArTeIuYhERKW4FqvS/pPXMoD0CM6l9ssLITPCp22eMMBUt5lAidC9v/A2ch27AkushjCK9jvh7AVUyo3KFAuJGqGzTF2oidoO30/pXoTcxEdLWot+XufqD9C6j6X8WKAVAP8fjHZlgQCz3gttQorAlI+NAG5XEfI7p2EWm+IANxhe1teLpyQGP+L2N9xWAvyAqamVqFph46Jurqe0KBSE6YlaoqErWd7NppPSLlfZDL1iO0TNpK29zGbdn8rER3Ss7ObYLvh2WLzedUkOihsBI7DXM6tEfAmlPFzZPV7OgEyGxMIjJtbU+tFhcG1buGE3xLBiYagh9F/jPY52ksYXx6bz3H2csxLYSNwvs0ETsWCKzWE7yVWudzDSqkXTKDDJiZv7+fNvAA4xmvN24DoNWKmOU7qycmVhbCD5RrIAy8wc8Rug9/lwGXjXTzuu2G7sBE4d3vlsgHnp9kZjL/zQd3ZEg0CdF9xTy6UyByxROMo/9okcC7xbtgHzOEy90oaxtiFtPsgnYC3990bKwbcLSP6V2JKfA32nWK+ArAPnDTng09Pr0b/nOnPgbpVegvQPdCFohzCN1zw0TkFkE/oB0v8Hu35kkucSkDKroTTzS3x1WIeoqVIhs/w5pixMeisqIz0ZmpqTrqdWtxUCv8hzqlRVEq0Xup1/wEXSVTS+7QM7wAAAABJRU5ErkJggg==);
  background-color: var(--grey);
}
.sidebar-nav_sidebar__2LCbk {
  grid-column: 1 / 7;
  grid-row: 1 / 3;
}

.sidebar-nav_sidebar__link__3GFBr {
  text-decoration: none;
  color: var(--black);
  display: grid;
  grid-template-columns: 1fr repeat(3, 70px 30px);
}

.sidebar-nav_sidebar__link_active__1uiFf {
  background-color: var(--cian);
}

.sidebar-nav_sidebar__text__2xr68 {
  grid-column: 2 / span 6;
}

.actual_container__29W54 {
  margin: 32px 0 32px 70px;
  max-width: 598px;
}

.actual_title__1hFqo {
  font-size: 14px;
  line-height: 19px;
  font-style: normal;
  font-weight: normal;
}

.actual_list__item__59q_u {
  font-size: 18px;
  line-height: 34px;
}
