.main__contentCards {
  display: grid;
  grid-template-columns: repeat(12, 70px);
  gap: 30px;
  margin-top: 136px;
}

.contentCards__title {
  font-size: 50px;
  margin: 0;
  grid-column-start: 1;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 1;
}


.contentCards__link {
  color: var(--black);
  transition: all 0.2s;
  grid-column-start: 9;
  grid-column-end: 12;
  grid-row-start: 2;
  grid-row-end: 2;
}

.contentCards__link:hover {
  color: var(--red);
}


.contentCards__cards {
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 100px;
  margin-top: 53px;
}