.informing {
  margin: 64px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 36px;
  max-width: 830px;
}

.informing__title {
  font-size: 36px;
  margin: 0 0 30px;
}

.informing__text {
  margin: 0 0 26px;
}