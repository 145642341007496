.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 70px 91px;
}

.auth__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
  margin: 55px auto 38px;
}

.auth__form {
  display:  flex;
  flex-direction: column;
  gap: 30px;
  min-width: 264px;
}

.auth__label {
  display: flex;
  flex-direction: column;
  gap: 14px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  position: relative;
}

.auth__input {
  border: 1px solid var(--grey);
  padding: 10px 7px;
  margin-left: 6px;
}

.auth__input:focus {
  box-shadow: 3px 3px 3px var(--grey);
}

.auth__input:focus-visible {
  outline: var(--grey);
  box-shadow: 3px 3px 3px var(--grey);
}

.auth__button_type_password {
  padding: 0;
  position: absolute;
  
  right: 10px;
  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-position: center;
  background-color: inherit;
  min-width: 32px;
  min-height: 32px;
  top: 50%;
}

.password__button_type_active {
  background-image: url(../../images/eye-active.svg);

}

.password__button_type_disabled {
  background-image: url(../../images/eye-disabled.svg);

}

.auth__button_type_submit {
  margin: 24px auto 35px;
  max-width: 169px;
  
}

.auth__inform_medium {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.auth__link_type_register {
  text-decoration: underline;
}