.main__control {
  display: grid;
  grid-template-columns: repeat(12, 70px);
  gap: 30px;
}

.control__info {
  grid-column-start: 1;
  grid-column-end: 7;
}

.control__title {
  font-size: 50px;
  line-height: 59px;
  margin-top: 17px;
}

.control__items {
  padding-left: 20px;
  padding-bottom: 0;
}

.control__item {
  line-height: 38px;
}

.control__buttons {
  display: flex;
  gap: 41px;
  margin-top: 63px;
}

.control__img {
  grid-column-start: 8;
  grid-column-end: 11;
}