.header { 
  border-bottom: 1px solid var(--grey);
  padding: 26px 0;
  animation: header .5s forwards ease;
  
}

.header__content {
  display: grid;
  grid-template-columns: 1fr repeat(11, 70px 30px) 70px 1fr;
  transition: left .2s linear;
  animation: header__content .5s forwards ease;
}

.header__logo {
  transition: filter 0.4s;
  grid-column:2 / 6;
}

.header__logo:hover {
  filter: brightness(0.8);
}

.header__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: 7 / 20;
}


.header__auth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 188px;
  grid-column: 21 / 25;

}

.header__auth_type_authorized {
  justify-content: flex-end;
}

.header__link {
  color: var(--black);
}

.header__link_active {
  color: var(--red)
}

.header__link_type_auth {
  border-bottom: 1px dashed var(--black);
  transition: border-color 0.2s;
}

.header__link_type_authorized {
  background-image: url(../../images/auth__icon.svg);
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 32px;
  min-height: 36px;
  cursor: pointer;
}

.header__link_type_auth:hover {
  border-color: var(--red);
}

@keyframes header {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes header__content {
  from {
      opacity: 0;
      transform: translate(0, 50px);
  }
  to {
      opacity: 1;
      transform: translate(0, 0);
  }
}