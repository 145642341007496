.sidebar {
  grid-column: 1 / 7;
  grid-row: 1 / 3;
}

.sidebar__link {
  text-decoration: none;
  color: var(--black);
  display: grid;
  grid-template-columns: 1fr repeat(3, 70px 30px);
}

.sidebar__link_active {
  background-color: var(--cian);
}

.sidebar__text {
  grid-column: 2 / span 6;
}
