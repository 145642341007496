.button {
  border-radius: 30px;
  padding: 18px 43px;
  border: 1px solid transparent;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer; 
}

.button_type_background {
  background-color: var(--red);
  color: var(--white);
  transition: box-shadow 0.5s;
}

.button_type_background:hover {
  box-shadow: 0px 5px 40px var(--hover-shadow-red);
}

.button_type_outline {
  background-color: var(--white);
  border-color: var(--red);
  color: var(--red);
  transition: background-color 0.8s;
}

.button_type_outline:hover {
  background-color: var(--red);
  color: var(--white);  
}