.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: .2;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;

}