.container {
  margin: 32px 0 32px 70px;
  max-width: 598px;
}

.title {
  font-size: 14px;
  line-height: 19px;
  font-style: normal;
  font-weight: normal;
}

.list__item {
  font-size: 18px;
  line-height: 34px;
}