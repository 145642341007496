.footer {
    background-color: var(--black);
    margin: 0 auto;
    width: 100%;
}

.footer__container {
  display: grid;
  max-width: 1170px;
  grid-template-columns: repeat(12, 70px);
  grid-column-gap: 30px;
  grid-row-gap: 19px;
  margin: 0 auto;
  color: var(--white);
  align-items: center;
  padding: 12px 0 22px;
}

.footer__logo_link {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
}

.footer__links {
    grid-column-start: 2;
    grid-column-end: 13;
    grid-row-start: 1;
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
}

.footer__copyright {
    grid-column-start: 2;
    grid-column-end: 11;
    grid-row-start: 2;
    text-align: center;
    margin: 0;
    align-self: end;
}

.footer__social {
    grid-column-start: 10;
    grid-column-end: 13;
    grid-row-start: 2;
    justify-self: end;
}